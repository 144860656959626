@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.mainContainer {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 1rem;
}

.hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}
